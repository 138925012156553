.white h1,
.white p,
.white a,
.closeWhite {
  color: #172242;
}
.slideWhite main {
  animation: 2s delayOpenClose forwards;
  visibility: hidden;
}

.white {
  overflow-y: scroll !important;
}

.grid h1 {
  grid-column: 2 / span 11;
}

.navWhite {
  width: 100%;
  grid-column: 2 / span 10;
  justify-self: start;
  margin-top: 2em;
  margin-bottom: 2.5em;
}

.navWhite a {
  margin-left: 0;
  border-bottom: 1px solid #172242;
  width: fit-content;
}

ul p {
  margin-top: 1em;
  margin-bottom: 1em;
  white-space: pre-line;
}

.white ul {
  max-width: 650px;
}

.adress {
  font-size: 18px;
  width: 50%;
  float: left;
}

.adress2 {
  padding-bottom: 4em !important;
}

ol {
  padding: 1em 0 0 0;
}

ol a {
  font-size: 1em;
  font-weight: 400;
}

.adress a {
  letter-spacing: 0.6px;
  font-size: 20px;
}

ol li:first-child {
  margin-bottom: 0.8em;
}
.space {
  height: 0.8em;
}

ol li {
  padding: 0;
  margin: 0.2em 0;
}
.active {
  color: #f48a1e !important;
  border-bottom: 1px solid #f48a1e !important;
}

.navWhite a:hover {
  color: #f48a1e;
  border-bottom: 1px solid #f48a1e;
}
.noDesktop {
  display: none;
}

.ulCards {
  display: grid;
  grid-template-areas: "li li";
  margin-bottom: 4em;
}

.card {
  width: 90%;
  display: block;
  gap: 0;
  margin-top: 3em;
  margin-bottom: 1em;
}

.cardImg {
  width: 80%;
  max-width: 400px;
  margin-bottom: 1em;
}

.cardText p {
  margin-top: 0em;
  margin-block-end: 0em;
}

.businessText {
  color: #172242;
}
#margherita{
  font-size: 16px;
  letter-spacing: 1.3px;
  margin-top: 1.3em;
  margin-bottom: 4em;
  font-weight: 400 !important;
}
#linkMargherita{
 text-decoration: underline;
}
/* DESK BIG  */
@media (min-width: 1200px) {
  .white header nav {
    grid-column: 6 / span 6;
  }

  .white .grid h1 {
    grid-column: 3 / span 10;
  }

  .navWhite {
    width: fit-content;
    grid-column: 3 / span 5;
    min-height: 500px;
  }

  .white ul {
    max-width: 800px;
    grid-column: 3 / span 10;
  }

  .cardText p {
    font-size: 20px;
  }
  #margherita{
    font-size: 20px;
    /*height: 200px;*/
    /*padding-bottom: 150px;*/
  }
}

/* DESK           DESKTOP           DESKTOP*/
@media (min-width: 769px) {
  .navWhite {
    max-width: 650px;
    display: grid;
    /*grid-template-columns: 1fr 2fr 1fr;*/
    gap: 2em 0;
    width: fit-content;
    grid-column: 3 / span 5;
    min-width: fit-content;
  }
  ul p,
  .modal p,
  #testo, #linkMargherita {
    letter-spacing: 0.7px;
    line-height: 1.2;
    font-weight: 400;
    font-size: 20px;
  }
  .businessText {
    letter-spacing: 0.7px;
    line-height: 1.2;
    font-weight: 400;
    font-size: 20px;
  }
  #firstBusinessText {
    margin-top: 1em;
  }
  .parentWhite {
    grid-column: 2 / span 10;
    display: grid;
  }
  .childWhite {
    grid-row-start: 1;
    grid-column-start: 1;
    z-index: 0;
    height: 66vh;
    max-width: 650px;
  }
  .modal {
    max-width: 650px;
    height: fit-content;
    grid-column: 2 / span 10;
    animation: 1.5s ease-out 0s 1 fadeInWhite;
  }
}
@keyframes fadeInWhite {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/* DESK           DESKTOP           DESKTOP*/
@media (min-width: 1200px) {
  ul p,
  .modal p {
    font-size: 20px;
  }
  .navWhite {
    max-width: 750px;
    max-height: 12em;
  }
  .childWhite {
    max-width: 850px;
  }
  .parentWhite {
    grid-column: 3 / span 10;
  }
  .modal {
    max-width: 750px;
    height: fit-content;
    grid-column: 3 / span 10;
  }
}
/*  MOBILE              MOBILE            MOBILE */
@media (max-width: 769px) {
  .navWhite {
    padding-bottom: 1em;
  }

  .white main h1 {
    margin-top: 1em;
    font-size: 2.9em;
  }

  .navWhite a {
    display: flex;
    margin-bottom: 0.5em;
    width: fit-content;
  }

  ul {
    grid-column: 2 / span 12;
  }
  li {
    padding: 0 2.5em 0 0;
  }

  ul p {
    padding-left: 0;
    font-size: 1.4em;
    letter-spacing: 0.5px;
    line-height: 1.15;
    font-weight: 400;
  }

  .adress {
    padding-bottom: 1em;
    font-size: 1.3em;
    width: 100%;
  }
  .textBetweenLink{
    padding-left: 0;
    font-size: 1.4em;
    letter-spacing: 0.5px;
    line-height: 1.15;
    font-weight: 400;
  }
  .businessText {
    padding-left: 0;
    font-size: 1.4em;
    letter-spacing: 0.5px;
    line-height: 1.15;
    font-weight: 400;
  }
  .noDesktop {
    display: block;
  }
  .noMobile {
    display: none;
  }

  .ulCards {
    grid-template-areas: "li";
  }

  .card {
    margin-top: 2em;
  }

  .cardImg {
    width: 100%;
  }

  .cardText p {
    font-size: 1.4em;
    margin-top: 0.2em;
    margin-left: 0em;
  }

  #Company {
    padding-top: 1em;
    margin-top: 0;
  }
  #Margherita{
    padding-right: 2rem;
    margin-bottom: 2rem;
  }
}

/* normal phones 14*/
@media (max-height: 569px) {
  .white main h1 {
    white-space: pre-line;
  }
}

.logoClose {
  font-size: 20px;
  margin-left: auto;
  color: #f48a1e !important;
  font-weight: 400;
  letter-spacing: 1.3px;
  grid-column: 2 / span 10;
  display: flex;
  margin-top: 32px;
}
.logoClose:hover {
  opacity: 0.6;
}

.childWhite {
  transition: 4s ease;
  opacity: 1;
}
.childWhite.semishow {
  transition: all 0.8s cubic-bezier(0.77, 0, 0.175, 1);
  opacity: 0.5;
}
#testo {
  margin-top: 2em;
  padding-left: 2.5em;
  animation: 1s linear 0s 1 fadeInWhite;
  color: #172242;
}
.semishow {
  opacity: 0.4;
}
/*todo togliere*/
.partWhite {
  margin-left: 5em;
  margin-top: 4em;
}
.linkWhite {
  display: flex;
}
.slideWhite main h1 {
  opacity: 1;
  animation: none;
}
.click {
  margin-top: 0.5em !important;
}
#closex {
  color: #f48a1e;
  cursor: pointer;
}
