:root {
  --borderMobile: 0px;
}
body {
  margin: 0;
  font-family: "mr-eaves-xl-sans", sans-serif;
  background-color: #fcfcfc;
  /*animation: loadMain 1.5s;*/
}

*::selection {
  background-color: transparent;
}

div, p, span{
  white-space: pre-line;
}

html,
body {
  overflow: hidden;
  scroll-behavior: smooth;
}

.blue,
.white {
  transition: all 1.3s cubic-bezier(0.77, 0, 0.175, 1);
  
}

.General {
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  animation: loadMain 1.5s;
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  align-items: center;
}

a {
  text-decoration: none;
  color: #fcfcfc;
  font-size: 22px;
}

header {
  align-items: end;
  height: 75px;
}

header a {
  animation: 2s delayOpenClose forwards;
  visibility: hidden;
}

nav {
  grid-column: 5 / span 12;
  margin-bottom: 0.4em;
  justify-self: end;
}
.logo {
  width: 170px;
  grid-column: span 6;
}

nav a {
  display: inline-flex;
  margin-left: 25px;
  font-size: 16px;
  letter-spacing: 1.3px;
}
.icon {
  fill: #222222;
  width: 0.7em;
  padding-right: 0.4em;
}

/* spostare slide blue */
main h1 {
  opacity: 0;
  animation: delayOpenClose 2s forwards;
  color: #f48a1e;
  font-weight: 200;
  letter-spacing: 1px;
  font-size: 48px;
}

main a {
  letter-spacing: 1px;
  font-size: 28px;
  font-weight: 200;
}

ul,
ol {
  list-style: none;
  grid-column: 2 / span 10;
  padding: 0;
}

.blue {
  background-color: #172242;
}

.white {
  overflow: hidden;
  background-color: #fcfcfc;
}

a:hover {
  cursor: pointer;
}

.childStart {
  cursor: pointer;
  text-align: center;
  opacity: 0;
  animation: 2s delayOpenClose forwards;
  position: relative;
}

.blue .parentStart {
  background-color: #172242;
}

footer {
  animation: 2s delayOpenClose forwards;
  visibility: hidden;
  letter-spacing: 0.3px;
  position: absolute;
  padding: 1em 0 1em 0;
  bottom: 0px;
  width: 100%;
  margin-left: 2.8em;
  color: #6d6d6d;
}

.white footer {
  background-color: #fcfcfc;
}
footer a,
footer a {
  font-size: 16px;
  color: #6d6d6d;
}

footer a:hover {
  text-decoration: underline;
}

/* DESKTOP          DESKTOP             DESKTOP           DESKTOP  */
@media (min-width: 769px) {
  /* VERSIONE PER RISOLVERE BUG CHROME ma perde transizione 
  .blue,.white{
    width: fit-content;
    overflow: hidden;
  }
  .homeBlue,.homeWhite{
    width: 50vw;
  }
  .slideBlue,
  .slideWhite {
    width: 88vw;
  }

  .closeBlue,
  .closeWhite {
    width: 12vw;
    cursor: pointer;
    white-space: pre-line;
    overflow: hidden;
  }*/

  /*VERSIONE USATA MA CON BUG CHROME*/
  /*.blue:has(.homeBlue),
  .white:has(.homeWhite) {
    width: 50vw;
    overflow: hidden;
  }
  .blue:has(.slideBlue),
  .white:has(.slideWhite) {
    width: 88vw;
  }
  .blue:has(.closeBlue),
  .white:has(.closeWhite) {
    width: 12vw;
    cursor: pointer;
    white-space: pre-line;
    overflow: hidden;
  }*/

  .textLogo {
    font-size: 13px;
    font-weight: 200;
    letter-spacing: 1.4px;
    margin-top: 0px;
    margin-left: 28px;
    width: max-content;
    /*position: absolute;*/
    visibility: hidden;
    animation: delayOpenClose 3s ease-in forwards;
  }

  .childStart {
    height: 80vh;
  }

  .icon {
    width: 16px;
    height: 16px;
  }

  /* .part {
    width: 100%;
    margin: auto;
  } */

  .closeBlue,
  .closeWhite {
    height: 80vh;
    overflow: hidden;
  }
  .white,
  .blue {
    overflow: hidden !important;
  }
  header {
    padding: 20px;
  }
}

/* aggiungo per ora qua per il Closeblu*/
.closeTitle {
  padding-top: 46px;
  font-size: 16px;
  font-weight: 700;
  margin-left: 0.5em;
  color: #fcfcfc;
  animation: 1.25s delayOpenClose forwards;
  visibility: hidden;
}

.homeTitle {
  margin-top: 25vh;
  color: #fcfcfc;
}

.homeTitle h1 {
  font-size: 48px;
  font-weight: 200;
}

.homeTitle p {
  font-size: 20px;
  margin: auto;
  max-width: 400px;
  letter-spacing: 0.6px;
}

/*style for the background with particles*/
.childStart {
  grid-row-start: 1;
  grid-column-start: 1;
  z-index: 0;
  width: 50vw;
}

.parentStart {
  display: grid;
}
.hidden {
  visibility: hidden !important;
}
.part {
  /*margin-top: 15vh;
    height: 50vh;*/
}

/*.partHome {
  height: 30vh;
  width: 25vw;
}*/

.rightPopUp,
.leftPopUp,
.underPopUp,
.middleLeftPopUp,
.middleTopPopUp {
  position: absolute;
  color: #f48a1e;
  letter-spacing: 0.6px;
}

.leftPopUp {
  transition: 0.4s 0.4s cubic-bezier(1, 0.09, 1, 1);
}

/* financial engeneering */
.rightPopUp {
  transition: 0.6s 0.5s cubic-bezier(1, 0.09, 1, 1);
}

.underPopUp {
  margin-top: 2em;

  transition: 0.4s 0.2s cubic-bezier(1, 0.09, 1, 1);
}

/* creativity */
.middleLeftPopUp {
  transition: 0.4s 0.3s cubic-bezier(1, 0.09, 1, 1);
}
.middleTopPopUp {
  transition: 0.3s 0.5s cubic-bezier(1, 0.09, 1, 1);
}

.blue .rightPopUp {
  top: 12%;
  left: 55%;
}
.blue .leftPopUp {
  top: 20%;
  left: 10%;
}
.blue .underPopUp {
  top: 68%;
  left: 20%;
}

.white .rightPopUp {
  top: 25%;
  left: 70%;
}
.white .leftPopUp {
  top: 15%;
  left: 25%;
}
.white .underPopUp {
  top: 70%;
  left: 50%;
}
.white .middleLeftPopUp {
  top: 58%;
  left: 65%;
}
.white .middleTopPopUp {
  top: 62%;
  left: 15%;
}

.underPopUp.hidden,
.leftPopUp.hidden,
.rightPopUp.hidden,
.middleTopPopUp.hidden,
.middleLeftPopUp.hidden {
  transition: 0.2s ease;
  opacity: 0;
}

.CookieConsent {
  opacity: 0;
  animation: delayOpenClose 1.4s 1s forwards;
}

/* DESKTOP BIG       DESKTOP BIG        DESKTOP BIG        DESKTOP BIG   */
@media (min-width: 1200px) {
  header {
    padding: 2em 50px;
    align-items: end;
    height: 75px;
  }
  .textLogo {
    font-size: 0.65em;
    font-size: 13px;
    font-weight: 200;
    letter-spacing: 1.4px;
    margin-top: 0px;
    margin-left: 28px;
    visibility: hidden;
    animation: delayOpenClose 3s ease-in forwards;
  }
  .closeTitle {
    padding-top: 58px;
    font-size: 22px;
    margin-left: 1em;
  }

  header a {
    font-size: 20px;
  }

  .icon {
    width: 20px;
    height: 20px;
  }

  main h1 {
    font-size: 64px;
  }

  main a {
    font-size: 34px;
  }

  .homeTitle h1 {
    font-size: 64px;
  }

  .homeTitle p {
    max-width: 500px;
  }

  .homeTitle p {
    font-size: 24px;
  }
  .underPopUp,
  .rightPopUp,
  .leftPopUp,
  .middleLeftPopUp,
  .middleTopPopUp {
    font-size: 20px;
  }
}
/* MOBILE               MOBILE          MOBILE        MOBILE*/
@media (max-width: 769px) {
  body {
    height: -webkit-fill-available;
  }
  html {
    height: -webkit-fill-available;
  }

  .General {
    display: block;
  }
  .blue {
    height: 0px;
    width: 100vw;
  }

  .white {
    height: 100vh;
    width: 100vw;
  }
  /*
  .blue:has(.slideBlue),
  .white:has(.slideWhite) {
    height: calc(var(--vh, 1vh) * 90);
  }

  .blue:has(.closeBlue),
  .white:has(.closeWhite) {
    height: calc(var(--vh, 1vh) * 10);
    cursor: pointer;
  }*/
  .textLogo {
    font-size: 0.65em;
    font-size: 13px;
    font-weight: 200;
    letter-spacing: 1.4px;
    margin-top: 0px;
    margin-left: 28px;
    visibility: hidden;
    animation: delayOpenClose 3s ease-in forwards;
    
    width: max-content;
  }

  /* EN/IT white */
  .homeWhite header {
    display: none;
  }

  header {
    padding: 14px 20px !important;
  }

  .rightPopUp,
  .leftPopUp,
  .underPopUp,
  .middleTopPopUp,
  .middleLeftPopUp {
    font-size: 16px;
    line-height: 1.5em;
  }
  nav {
    grid-column: 10 / span 6;
    margin-bottom: 0em;
  }
  .logo {
    width: 140px;
  }

  main h1 {
    margin-top: 1em;
    font-size: 3em;
  }

  .homeTitle p {
    display: none;
  }

  .closeTitle {
    padding-left: 0.5em;
    padding-top: 0.8em;
    margin-top: 0em;
    font-size: 1.5em;
    white-space: normal;
  }

  .childStart {
    width: 100%;
    height: calc(var(--vh, 1vh) * 40);
    display: grid;
    place-items: center;
  }

  .childStart p {
    text-align: left !important;
  }
  .homeTitle {
    margin-top: -2.5em;
    text-align: left;
    color: #fcfcfc;
    /* padding: 20px; */
  }

  .homeTitle p {
    max-width: 450px;
  }

  .leftPopUp,
  .rightPopUp,
  .underPopUp,
  .middleTopPopUp,
  .middleLeftPopUp {
    position: static;
    display: inline;
    visibility: visible;
    justify-content: space-between;
    padding-right: 0.8em;
  }
  /* titoli arancioni */
  .underPopUp.hidden,
  .leftPopUp.hidden,
  .rightPopUp.hidden,
  .middleTopPopUp.hidden,
  .middleLeftPopUp.hidden {
    visibility: visible !important;
    opacity: 1;
  }

  footer {
    display: none;
  }

  .slideWhite footer {
    display: inline;
    padding: 0em;
    text-align: center;
    margin-left: 0;
    font-size: 14px !important;
  }

  footer a {
    font-size: 14px !important;
  }
}

/* ANIMATIONS             ANIMATIONS            ANIMATIONS */

@keyframes delayOpenClose {
  40% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}
@keyframes loadMain {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes delayOpenCloseOpacity {
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/*  scrollbar  */
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: #f48a1e;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #f48a1e;
}
.activeOrange {
  color: #f48a1e !important;
}

.CookieConsent {
  position: fixed;
  z-index: 999;
}
