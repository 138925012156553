.childHome {
  font-size: 1.5em;
  grid-row-start: 1;
  grid-column-start: 1;
  z-index: 0;
}

.platformLinks {
  display: flex;
  grid-column: 1 / span 12;
  justify-content: space-between;
  animation: 3s delayOpenClose forwards;
  visibility: hidden;
}

.underelement {
  /* TODO animazione che salta fuori in ritardo */
  animation: 0.7s opacity;
  color: #fcfcfc;
}

.onlyMobile {
  visibility: hidden !important;
}

.parentHome {
  display: grid;
}

/* DESKTOP BIG           DESKTOP BIG         DESKTOP BIG    */
@media (min-width: 1200px) {
  .element {
    margin-top: 23vh;
  }
  /* TODO */
  .element a {
    font-size: 40px;
  }

  .underelement {
    font-size: 24px !important;
  }
}

/* DESKTOP       DESKTOP        DESKTOP       DESKTOP    */
@media (min-width: 769px) {
  .childHome a {
    padding-bottom: 10px;
    border-bottom: 0.5px solid #fcfcfc;
  }

  .underelement {
    margin-top: 1.2em;
    font-weight: 200;
    font-size: 20px;
    letter-spacing: 1px;
  }
  .element {
    margin-top: 12vw;
    margin-left: 7.3vw;
  }
  .parentHome {
    height: 50vh;
  }

  .childHome {
    width: 29.3vw;
    height: 29.3vw;
  }
}
/* MOBILE                 MOBILE          MOBILE */
@media (max-width: 769px) {
  header {
    padding: 20px;
  }

  .blue a {
    padding-left: 0 !important;
  }
  .blue .underelement {
    padding-left: 0 !important;
    font-weight: 200;
  }

  .onlyMobile {
    visibility: visible;
  }

  .childHome a {
    padding-bottom: 10px;
    border-bottom: 0.9px solid #fcfcfc;
    font-size: 36px !important;
  }

  .underelement {
    margin-top: 0.8em;
  }

  /* mobile but desktop viewport */
  .platformLinks {
    height: -webkit-calc(calc(var(--vh, 1vh) * 100) - 198px - calc(var(--vh, 1vh) * 10)); /*per l'altezza prendo l'altrezza totale topgo i pixer di header e titolo e topgo la parte bianca sotto */
    display: grid;
    overflow-y: scroll;
  }

  .childHome {
    z-index: 0;
    height: calc(var(--vh, 1vh) * 36);
    width: 95vw;
    max-width: 18em; /*centra le particelle nel mobile largo*/
  }
  .element {
    margin-left: 8.3vw;
    margin-top: calc(var(--vh, 1vh) * 13);
  }
  .partHome {
    transition: 1.5s linear;
  }
  .partHome.hidden {
    transition: 0.5s ease;
    opacity: 0;
  }
  .element.last{
    padding-bottom: 170px;
  }
}

/* media query that hadle mobile's vertical geometry list  */

@media (max-width: 1000px) {
  .element a {
    font-size: 1.2em;
  }
}
@media (max-width: 900px) {
  .element a {
    font-size: 1em;
  }
}

/* normal phones 14*/
/*@media (max-height: 769px) {
  .platformLinks {
    height: calc(var(--vh, 1vh) * 60);
  }
}

/* small phones SE */
@media (max-height: 669px) {

  /* TODO margin-bottom iphone se last .childhome */
  /* .childHome {
    z-index: 0;
    height: calc(var(--vh, 1vh) * 36);
  } */
}
